import React, { Component } from "react";
// import PropTypes from 'prop-types';
import "./Partners.css";
import asurf from "../../assets/img/asurf.png";
import blueNile from "../../assets/img/blueNile.jpg";
import embassy from "../../assets/img/embassy.png";
import efa from "../../assets/img/efa.png";

import Details from "../Details/Details.js";

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section>
        <div className="partner flex">
          <Details
            title={this.props.title}
            linedTitle=""
            titleTop="Our Partners"
          ></Details>

          <div className="logoBox">
            <img className="logo" alt="logo" src={asurf} />
            <img className="logo2" alt="logo" src={blueNile} />
            <div className="logoTextBox">
              <img className="logo" alt="logo" src={embassy} />
              <p>Embassy of the Netherlands in Ethiopia</p>
            </div>

            <img className="logo" alt="logo" src={efa} />
          </div>
        </div>
      </section>
    );
  }
}

export default Partners;
