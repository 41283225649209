import React, { Component } from 'react';
import './Whitepaper.css';


class Whitepaper extends Component {
  componentDidMount() {
    document.title = "martian_whitepaper.pdf";
  }

  render() {
    return (
      <section className="content">
        <iframe
          title="martiannetwork_whitepaper.pdf"
          src={require('../../assets/pdf/martiannetwork_whitepaper.pdf')}
          width="100%"
          height="100%"
        />
      </section>
    )
  }
}

export default Whitepaper;
