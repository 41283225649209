import React, { Component } from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from "react-router-hash-link";
import "./Footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FooterMartianLogo from "../../assets/img/footer-martian-logo.svg";

class Footer extends Component {
  render() {
    const { navLinks, socialIcons } = this.props;

    return (
      <footer className="footer has-mobile-padding flex">
        <div className="footer-top flex">
          <div className="footer-box contact flex">
            <h4>
              <img src={FooterMartianLogo} alt="Logo" />
            </h4>

            <div className="inquires">
              {/* <div className="mail flex">
                <h2>Press & Media Inquiries:</h2>
                <a href="mailto:press@martian.network">press@martian.network</a>
              </div> */}

              <div className="general flex">
                <h2>General Inquiries:</h2>
                <a href="mailto:info@martian.network">info@martian.network</a>
              </div>
            </div>
          </div>

          <div className="footer-box address flex">
            {/*<h4></h4>*/}

            <p>Panamalaan 7C , 1019 AS Amsterdam, The Netherlands</p>
          </div>

          <div className="footer-box nav flex">
            <nav className="footer-nav flex">
              <ul className="flex">
                <li>
                  <Link smooth to="/#header">
                    Home
                  </Link>
                </li>
                {navLinks.map((link, index) => {
                  return (
                    <li key={index}>
                      <Link smooth to={`#${link}`}>
                        {link}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>

        <div className="footer-bottom flex" style={{marginLeft: '10px', marginTop: "10px"}} >
          <div className="footer-bottom-left" >
            <p>Join the discussion!</p>
          </div>

          <div className="footer-bottom-right social">
            {socialIcons.map((icon, index) => {
              return (
                <a
                  href={`${icon.url}`}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={icon.name}
                >
                  <FontAwesomeIcon className="icon" icon={icon.image} />
                </a>
              );
            })}
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  navLinks: PropTypes.array.isRequired,
  socialIcons: PropTypes.array.isRequired,
};

export default Footer;
