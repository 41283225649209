import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Why.css';

import Details from '../Details/Details.js';


class Why extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLeft: [
        {name: 'Distributed trust system with transparency', class: 'trust'},
        {name: 'Encrypted file sharing', class: 'efs'},
        // {name: 'Smart Contract', class: 'sc'}
      ],
      navRight: [
        {name: 'Decentralized streaming', class: 'ds'},
        {name: 'Transparency through the blockchain', class: 'tb'},
        // {name: 'Encrypted file sharing.', class: 'efs'}
      ]
    }
  }
  render() {
    const { navLeft, navRight } = this.state;


    return (
      <section>
        <div id="why?" className="why has-primary has-desktop-padding has-mobile-padding">
          <Details
            title={this.props.title}
            linedTitle=""
            titleTop="WHY MARTIAN?"
            titleBottom=""
          />

          <div className="reasons flex">
            <ul className="menu-left flex">
            {navLeft.map((link, index) => {
              return  <li key={index} className={link.class}>
                        <p>{link.name}</p>
                      </li>
            })}
            </ul>

            <ul className="menu-right flex">
            {navRight.map((link, index) => {
              return  <li key={index} className={link.class}>
                        <p>{link.name}</p>
                      </li>
            })}
            </ul>
          </div>
          <div className="stripe-white"/>
        </div>
      </section>
    )
  }
}

Why.propTypes = {
  title: PropTypes.string.isRequired
}


export default Why;
