import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

import './Navbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MartianLogoLeft from '../../assets/img/martian-logo-left.svg';

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      isTop: true,
      dropdownId: 1,
      activeId: null,
      isActive: true
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  componentDidMount() {
    // attaches navbar to top of page on scroll
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    });
  }

  toggleActive(){
    this.setState({
      toggleActive: !this.state.toggleActive
    })
  }

  render() {
    const { navLinks, hamburgerIcon } = this.props;
    const { toggleActive } = this.state;

    return (
      <section>
        <nav className={`navbar flex ` + ((toggleActive === true) ? 'active' : '')} style={{ backgroundColor: this.state.isTop ? 'rgba(1, 1, 1, 0.5)' : 'black'}}>
          <div className="nav-left flex">
            <a href="http://martian.network" aria-label="Brand Logo">
              <img src={MartianLogoLeft} alt="Martian"/>
            </a>
            <div className="navbar-brand flex"></div>
          </div>

          <div className="dropdown-menu flex">
            <div className="dropdown">
              <div className="dropdown-btn flex" onClick={this.toggleActive}>
                <FontAwesomeIcon icon={hamburgerIcon} style={{color: '#767676', marginTop: '-10px'}}/>
              </div>
              <div className={`dropdown-content ` + ((toggleActive === true) ? 'active' : 'hidden')}>
                <Link smooth to='/#header'>Home</Link>
                {navLinks.map((link, index) => {
                  return  <Link
                            key={index}
                            smooth to={`/#${link}`}>{link.charAt(0).toUpperCase() + link.slice(1)}
                          </Link>
                })}
              </div>
            </div>
          </div>

          <div className="nav-right flex">
            <div className="menu flex">
              <Link smooth to='/#header'>Home</Link>
              {navLinks.map((link, index) => {
                return  <Link key={index} smooth to={`/#${link}`}>{link.charAt(0).toUpperCase() + link.slice(1)}</Link>
              })}
            </div>
          </div>
        </nav>
          <div className={`nav-logo flex ` + ((toggleActive === true) ? 'active' : '')}>
              <a href="http://martian.network" aria-label="Brand Logo">
                  <img src={MartianLogoLeft} alt="Martian"/>
              </a>
          </div>
      </section>
    )
  }
}

Navbar.propTypes = {
  navLinks: PropTypes.array.isRequired,
  hamburgerIcon: PropTypes.object.isRequired
};


export default Navbar;
