import React, { Component } from "react";
import PropTypes from "prop-types";
// import InlineSVG from 'svg-inline-react';
import "./Functionalities.css";
import MartianPLace from "../../assets/img/martianplace.png";
import MartianFuel from "../../assets/img/mFuel.png";
// import DigitalPhoto from '../../assets/img/digital-photo.svg';

import Details from "../Details/Details.js";

// const videoIcon = `<svg height="78" viewBox="0 0 90 78" width="90" xmlns="http://www.w3.org/2000/svg"><g fill="#CF2930" transform="translate(-371 -3319)"><path d="m456.95 3378.594a3.933 3.933 0 0 1 -3.934 3.934h-74.149a3.933 3.933 0 0 1 -3.933-3.934v-51.727a3.933 3.933 0 0 1 3.933-3.933h74.149a3.933 3.933 0 0 1 3.934 3.933zm-31.076 14.358h-19.668v-6.49h19.668zm27.255-73.952h-74.179c-4.338 0-7.95 3.495-7.95 7.833v51.72c0 4.338 3.612 7.908 7.95 7.908h23.321v6.49h-9.137a1.967 1.967 0 1 0 0 3.934h45.708a1.967 1.967 0 1 0 0-3.933h-9.035v-6.49h23.32c4.338 0 7.952-3.57 7.952-7.909v-51.72c0-4.338-3.614-7.833-7.951-7.833z"/><path d="m448.689 3374.66h-66.281v-43.86h66.28zm3.934-45.826c0-1.086-.88-1.967-1.967-1.967h-70.216c-1.087 0-1.967.88-1.967 1.967v47.793c0 1.087.88 1.967 1.967 1.967h70.215c1.086 0 1.967-.88 1.967-1.967z"/><path d="m412.303 3346.372 8.908 5.277-8.908 5.277zm-2.97 15.713c.292.17.622.259.959.259.35 0 .694-.096.995-.275l14.729-8.728a1.967 1.967 0 0 0 -.002-3.384l-14.735-8.729a1.911 1.911 0 0 0 -1.947-.016 1.94 1.94 0 0 0 -.964 1.709v17.455a1.939 1.939 0 0 0 .965 1.71z"/></g></svg>`;
// const musicIcon = `<svg height="78" viewBox="0 0 78 78" width="78" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="m995.619 3379.212c0 7.945-6.463 14.407-14.407 14.407h-41.974c-7.944 0-14.407-6.462-14.407-14.407v-41.974c0-7.945 6.463-14.407 14.407-14.407h41.974c7.944 0 14.407 6.462 14.407 14.407zm-14.407-59.212h-41.974c-9.505 0-17.238 7.733-17.238 17.238v41.974c0 9.505 7.733 17.238 17.238 17.238h41.974c9.505 0 17.238-7.733 17.238-17.238v-41.974c0-9.505-7.733-17.238-17.238-17.238z"/><path id="b" d="m973.775 3376.022c-4.237 0-7.684-2.993-7.684-6.673 0-3.681 3.447-6.676 7.684-6.676s7.686 2.996 7.686 6.676-3.449 6.673-7.686 6.673zm-20.629-29.32v-7.458l28.315-3.676v7.456zm-10.618 38.422c-4.294 0-7.786-2.993-7.786-6.674 0-3.68 3.492-6.675 7.786-6.675s7.787 2.994 7.787 6.675-3.493 6.674-7.787 6.674zm40.166-52.572-31.146 4.043a1.416 1.416 0 0 0 -1.233 1.405v34.01c-1.941-1.88-4.709-3.065-7.787-3.065-5.855 0-10.618 4.266-10.618 9.507 0 5.24 4.763 9.504 10.618 9.504 5.856 0 10.618-4.265 10.618-9.506v-28.892l28.315-3.678v17.001c-1.92-1.864-4.65-3.038-7.686-3.038-5.799 0-10.516 4.266-10.516 9.507s4.719 9.505 10.516 9.505c5.799 0 10.518-4.264 10.518-9.505v-35.395a1.413 1.413 0 0 0 -1.599-1.403z"/></defs><g transform="translate(-921 -3319)"><g><use fill="#CF2930" xlink:href="#a"/><use fill="none" stroke="#CF2930" stroke-miterlimit="50" xlink:href="#a"/></g><g><use fill="#CF2930" xlink:href="#b"/><use fill="none" stroke="#CF2930" stroke-miterlimit="50" xlink:href="#b"/></g></g></svg>`;
// const photosIcon = `<svg height="76" viewBox="0 0 76 76" width="76" xmlns="http://www.w3.org/2000/svg"><g fill="#CF2930" transform="translate(-1466 -3320)"><path d="m1473.96 3323.78h59.58a4.194 4.194 0 0 1 4.18 4.18v36.732l-15.303-15.288a1.88 1.88 0 0 0 -2.669 0l-22.091 22.092-12.234-12.234a1.88 1.88 0 0 0 -2.67 0l-12.973 12.974v-44.276a4.194 4.194 0 0 1 4.18-4.18zm59.58 67.94h-59.58a4.194 4.194 0 0 1 -4.18-4.18v-9.95l14.316-14.317 12.234 12.234c.74.74 1.928.74 2.669 0l22.091-22.092 16.63 16.63v17.495a4.194 4.194 0 0 1 -4.18 4.18zm-67.54-4.18c0 4.397 3.58 7.96 7.96 7.96h59.58c4.397 0 7.96-3.579 7.96-7.96v-59.58c0-4.396-3.579-7.96-7.96-7.96h-59.58c-4.396 0-7.96 3.58-7.96 7.96z"/><path d="m1489.403 3334.81a5.837 5.837 0 0 1 5.831 5.832c0 3.208-2.622 5.831-5.831 5.831s-5.831-2.623-5.831-5.831a5.837 5.837 0 0 1 5.831-5.832zm0 15.443c5.307 0 9.611-4.32 9.611-9.611 0-5.292-4.32-9.612-9.611-9.612-5.292 0-9.611 4.32-9.611 9.612 0 5.291 4.304 9.61 9.611 9.61z"/></g></svg>`;
// const lineIcon = `<svg height="5" viewBox="0 0 50 5" width="30" xmlns="http://www.w3.org/2000/svg"><path d="m393.5 3483c-1.25 0-2.5-1.25-2.5-2.5s1.25-2.5 2.5-2.5h45c1.25 0 2.5 1.25 2.5 2.5s-1.25 2.5-2.5 2.5z" fill="#CF2930" transform="translate(-391 -3478)"/></svg>`;

class Functionalities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          name: "Martianplace",
          src: MartianPLace,
          link: "https://martianplace.io/",
        },
        // {
        //   name: "Martianfuel",
        //   src: MartianFuel,
        //   link: "https://martianfuel.io/",
        // },
        // {name: 'Digital Photos', src: DigitalPhoto}
      ],
    };
  }

  render() {
    const { services } = this.state;

    services.forEach((service) => {
      if (service.name === "Video Streaming") {
        service.text = (
          <p>
            The global estimation of the video streaming market is now a
            reported $20.1 billion in revenue and growing. (Forbes)
          </p>
        );
      } else if (service.name === "Music Streaming") {
        service.text = (
          <p>
            The global recorded music industry is now worth a reported $15.7
            billion. The growth of the industry is due to the continued
            explosion of streaming. (Forbes)
          </p>
        );
      } else if (service.name === "Digital Photos") {
        service.text = (
          <p>
            The digital photography market, including cameras, software and
            processing equipment is expected to grow to $110.79 billion by the
            year 2021. (Forbes)
          </p>
        );
      }
    });

    return (
      <section>
        <div
          id="functionalities"
          className="functionalities has-desktop-padding has-mobile-padding flex"
        >
          <Details
            title={this.props.title}
            linedTitle=""
            titleTop="KEY ECOSYSTEM"
            titleBottom=""
          />
          <div className="services">
            {services.map((service, index) => {
              return (
                <div key={index} className="service flex">
                  <div className="service-top flex">
                    <img
                      style={{
                        width: index === 1 && "140px",
                        height: index === 1 && "140px",
                      }}
                      src={service.src}
                      alt="Service"
                    />
                    <div className="service-title flex">
                      <a
                        href={service.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h3>{service.name}</h3>
                      </a>
                    </div>
                  </div>
                  {service.text}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

Functionalities.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Functionalities;
