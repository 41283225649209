import React, { Component } from "react";
import PropTypes from "prop-types";
//imports child components
import Header from "../../components/Header/Header";
import About from "../../components/About/About";
// import Infrastructure from '../../components/Infrastructure/Infrastructure';
import Functionalities from "../../components/Functionalities/Functionalities";
import Why from "../../components/Why/Why";
import Team from "../../components/Team/Team";
import Roadmap from "../../components/Roadmap/Roadmap";
// import Tokens from '../../components/Tokens/Tokens';
// import Faq from '../../components/Faq/Faq';
import Footer from "../../components/Footer/Footer";
// import Blockchain from "../../components/Blockchain/Blockchain";
import Partners from "../../components/Partners/Partners";

class Layout extends Component {
  render() {
    const {
      navLinks,
      socialIcons,
      hamburgerIcon,
      arrowUp,
      arrowDown,
      linkedin,
    } = this.props;

    return (
      <section>
        <Header
          navLinks={navLinks}
          socialIcons={socialIcons}
          hamburgerIcon={hamburgerIcon}
        />
        {/* <Blockchain title="blockchain"/> */}
        <About title="about" />

        <div className="has-network-wallpaper">
          {/*<Infrastructure title="infrastructure"/>*/}
          <Functionalities title="functionalities" />
          {/* <Why title="why" /> */}
        </div>

        {/* <Tokens title="tokens"/> */}
        <div className="has-network-wallpaper">
          <Roadmap title="roadmap" />
        </div>
        <Team title="team" linkedin={linkedin} />
        {/* <Faq title="faq" arrowUp={arrowUp} arrowDown={arrowDown}/> */}

        <Partners title="partner" />

        <div className="has-stars-wallpaper">
          <Footer navLinks={navLinks} socialIcons={socialIcons} />
        </div>
      </section>
    );
  }
}

Layout.propTypes = {
  navLinks: PropTypes.array.isRequired,
  socialIcons: PropTypes.array.isRequired,
  hamburgerIcon: PropTypes.object.isRequired,
  arrowUp: PropTypes.object.isRequired,
  arrowDown: PropTypes.object.isRequired,
  linkedin: PropTypes.object.isRequired,
};

export default Layout;
