import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import InlineSVG from 'svg-inline-react';
import './Header.css';

import Navbar from '../../components/Navbar/Navbar';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const whitepaperIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 30.361 27.083"><path id="Path_2247" data-name="Path 2247" d="M30.106,22.631c-1.081-1.951-5.813-3.194-9.992-3.43q-.567-.606-1.154-1.277c-3.591-4.106-5.045-10.073-5.589-13.234-.065-.67-.136-1.227-.2-1.641-.048-.33-.15-1.018-.866-1.018A.818.818,0,0,0,11.7,2.3a1.1,1.1,0,0,0-.172.966c.051.415.128.97.236,1.6.306,3.209.45,9.32-2.02,14.174q-.386.759-.769,1.455C4.356,21.848.626,24.245.08,26.234a1.964,1.964,0,0,0,.485,1.944,2.815,2.815,0,0,0,2.087.936c2.187,0,4.68-2.436,7.409-7.236a23.438,23.438,0,0,1,3.588-.66c.454-.046,1.19-.142,1.641-.211a22.5,22.5,0,0,1,4.1-.222c3.351,3.483,6.09,5.249,8.144,5.249A2.86,2.86,0,0,0,30.1,24.52,1.873,1.873,0,0,0,30.106,22.631ZM2.651,27.5a1.23,1.23,0,0,1-.921-.438.357.357,0,0,1-.094-.4c.282-1.024,2.648-2.779,6.106-4.054C5.788,25.692,3.918,27.5,2.651,27.5Zm12.392-8.087c-.429.066-1.127.157-1.558.2a23.616,23.616,0,0,0-2.4.367l.1-.2a22.966,22.966,0,0,0,2.239-8.5,23.754,23.754,0,0,0,4.321,7.71c.052.06.106.12.157.182A22.354,22.354,0,0,0,15.043,19.413ZM28.7,23.727a1.261,1.261,0,0,1-1.2.695h0c-1.283,0-3.256-1.215-5.618-3.442,3.656.459,6.333,1.566,6.813,2.433A.265.265,0,0,1,28.7,23.727Z" transform="translate(0 -2.031)" fill="#fff"/></svg>`;const playIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 21.183 23.952"><g id="_x33_56._Play" transform="translate(0)"><path id="Path_2249" data-name="Path 2249" d="M51.9,37.7,34.707,27.674c-1.566-.915-2.83-.183-2.821,1.629l.088,19.9c.008,1.811,1.287,2.549,2.858,1.641L51.89,40.994C53.459,40.089,53.463,38.613,51.9,37.7Z" transform="translate(-31.886 -27.279)" fill="#fff"/></g></svg>`;const redlineIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="8" viewBox="0 0 30 5"><rect id="Rectangle_1010" data-name="Rectangle 1010" width="5" height="30" rx="2.5" transform="translate(0 5) rotate(-90)" fill="#cf2930"/></svg>`;


class Header extends Component {
  render() {
    const { navLinks, hamburgerIcon } = this.props;

    return (
      <section>
        <header id="header" className="has-globe-wallpaper flex">
          <div className="header-top">
            <Navbar navLinks={navLinks} hamburgerIcon={hamburgerIcon}/>
          </div>

          <div className="header-middle flex">
            <h1>Decentralized<br/> Entertainment Ecosystem.</h1>
            {/*<p>*/}
            {/*  Martian is dedicated to building a revolutionary, incentivized,*/}
            {/*  peer-to-peer file-sharing platform which enables users to share*/}
            {/*  digital content and take control over their own content.*/}
            {/*</p>*/}

            {/*<div className="buttons-wrapper flex">*/}
            {/*  <button className="btn btn-primary flex" onClick={this.goToPDF}>*/}
            {/*    <span><InlineSVG src={whitepaperIcon} className="icon"/></span> Whitepaper*/}
            {/*  </button>*/}
            {/*  <Link to="/">*/}
            {/*    <button className="btn btn-secondary flex coming-soon">*/}
            {/*      <span className="flex"><InlineSVG src={playIcon} className="icon flex"/>Play Video<xd/span>*/}
            {/*    </button>*/}
            {/*  </Link>*/}
            {/*</div>*/}
              <div className="nav-bottom"><a href="/#about">Learn more</a></div>
          </div>

          {/*<div className="redline-wrapper flex">*/}
          {/*  <InlineSVG src={redlineIcon} className="icon"/>*/}
          {/*  <span>Follow Us</span>*/}
          {/*</div>*/}

          {/*<div className="header-bottom social flex">*/}
          {/*  {socialIcons.map((icon, index) => {*/}
          {/*    return  <a*/}
          {/*              href={`${icon.url}`}*/}
          {/*              target="_blank"*/}
          {/*              rel="noopener noreferrer"*/}
          {/*              className="social-icon"*/}
          {/*              key={index}*/}
          {/*              aria-label={icon.name}*/}
          {/*              >*/}
          {/*                <div className="icon-social"><FontAwesomeIcon icon={icon.image}/></div>*/}
          {/*            </a>*/}
          {/*  })}*/}
          {/*</div>*/}
        </header>
      </section>
    )
  }

  // goToPDF() {
  //     const win = window.open('./static/media/martiannetwork_whitepaper.04967c54.pdf', '_blank');
  //     win.focus();
  // }
}

Header.propTypes = {
  navLinks: PropTypes.array.isRequired,
  socialIcons: PropTypes.array.isRequired,
  hamburgerIcon: PropTypes.object.isRequired
};

export default Header;
