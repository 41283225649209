import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Roadmap.css";

import Details from "../Details/Details.js";

class Roadmap extends Component {
  render() {
    return (
      <section id="roadmap">
        <Details
          title={this.props.title}
          linedTitle=""
          titleTop="ROADMAP"
          titleBottom=""
        />

        <div className="rContainer">
          <div className="rBox">
            <div className="cardBox">
              <div className="cardBox2">
                <div className="cardCurrent">
                  <h3 className="cardTitle">Q4 2018 </h3>

                  <div className="list">
                    <p className="text">Register Company</p>
                  </div>

                  <div className="tracker">
                    <div className="trackerBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardLeft">
              <div className="cardLeftBox">
                <div className="cardCurrent">
                  <h3 className="cardTitle">2018-2021</h3>
                  <div className="list">
                    <p className="text">R&D Entertainment industry Global</p>
                  </div>

                  <div className="trackerLeft">
                    <div className="trackerLeftBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardBox">
              <div className="cardBox2">
                <div className="cardCurrent">
                  <h3 className="cardTitle">Q4 2021</h3>

                    {/* <div className="list">
                      <p className="text">Creation Martian Fuel</p>
                    </div> */}

                  <div className="list">
                    <p className="text">
                    Development NFT Marketplace: Martianplace
                    </p>
                  </div>

                  <div className="tracker">
                    <div className="trackerBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardLeft">
              <div className="cardLeftBox">
                <div className="cardCurrent">
                  <h3 className="cardTitle">Q1 2022 </h3>

                  <div className="list">
                    <p className="text">Partnerships </p>
                  </div>

                  <div className="list">
                    <p className="text">Visit first the NFT exibition in Nigeria</p>
                  </div>

                  <div className="trackerLeft">
                    <div className="trackerLeftBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardBox">
              <div className="cardBox2">
                <div className="cardCurrent">
                  <h3 className="cardTitle">Q2 2022</h3>
                  {/* <div className="list">
                    <p className="text">Creation Martian Stream</p>
                  </div> */}

                  {/* <div className="list">
                    <p className="text">
                      Development NFT Marketplace (Martianplace)
                    </p>
                  </div> */}

                  <div className="list">
                    <p className="text">Establish partnerships</p>
                  </div>

                  <div className="list">
                    <p className="text">Start pre-production phase 3 Colddishes</p>
                  </div>

                  <div className="tracker">
                    <div className="trackerBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardLeft">
              <div className="cardLeftBox">
                <div className="cardCurrent">
                  <h3 className="cardTitle">Q3-Q4 2022</h3>

                  <div className="list">
                    <p className="text">Release NFT Marketplace: Martianplace</p>
                  </div>

                  <div className="trackerLeft">
                    <div className="trackerLeftBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardBox">
              <div className="cardBox2">
                <div className="cardCurrent">
                  <h3 className="cardTitle">Coming soon roadmap 2023</h3>

                  <div className="tracker">
                    <div className="trackerBox">
                      <span className="active"></span>
                    </div>

                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="roadmap flex has-desktop-padding has-mobile-padding">
          {/*<div className="logo"></div>*/}
          {/*<Details*/}
          {/*    title={this.props.title}*/}
          {/*    linedTitle=""*/}
          {/*    titleTop="ROADMAP"*/}
          {/*    titleBottom="2019"*/}
          {/*/>*/}
          {/*<div className="roadmapItemWrapper">*/}
          {/*    <div className="roadmapItem">*/}
          {/*        <div className="date">Q3 | Q4 2018</div>*/}
          {/*        <div className="name">GENESIS</div>*/}
          {/*        <div className="list">*/}
          {/*            <ul>*/}
          {/*                <li>Start Company</li>*/}
          {/*                <li>Finish Litepaper</li>*/}
          {/*                <li>Team Expansion</li>*/}
          {/*            </ul>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
        {/* <div className="roadmap-img"></div> */}
      </section>
    );
  }
}

Roadmap.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Roadmap;
