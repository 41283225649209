import React, { Component } from "react";
import PropTypes from "prop-types";
import "./About.css";

import Details from "../Details/Details.js";

import WhatIsMartianGrapic from "../../assets/img/what-is-martian-grapic.svg";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: [{ name: "mission" }, { name: "vision" }, { name: "motto" }],
    };
  }

  render() {
    const { goals } = this.state;

    goals.forEach((goal) => {
      if (goal.name === "vision") {
        goal.element = (
          <p>
            To bring people together with the same passion, ideas and creativity
            developing opportunities to share content globally.
          </p>
        );
      } else if (goal.name === "mission") {
        goal.element = (
          <p>
            Our mission is to create a decentralized P2P applications where
            digital content is stored and shared. We are creating a ecosystem
            where people can take control over their own,production distribution
            & exibition.
          </p>
        );
      } else if (goal.name === "motto") {
        goal.element = <p>Your Dream is our Dream (YDIOD)</p>;
      }
    });

    return (
      <section>
        <div
          id="about"
          className="about has-desktop-padding has-mobile-padding flex"
        >
          <div className="about-top flex">
            <Details
              title={this.props.title}
              linedTitle=""
              titleTop="What is Martian Network"
              titleBottom=""
            />
            <div className="buttons-wrapper flex">
              {/*<button className="btn btn-primary flex"><span><i className="icon"><svg*/}
              {/*xmlns="http://www.w3.org/2000/svg"*/}
              {/*width="23" height="23"*/}
              {/*viewBox="0 0 30.361 27.083"><path*/}
              {/*id="Path_2247" data-name="Path 2247"*/}
              {/*d="M30.106,22.631c-1.081-1.951-5.813-3.194-9.992-3.43q-.567-.606-1.154-1.277c-3.591-4.106-5.045-10.073-5.589-13.234-.065-.67-.136-1.227-.2-1.641-.048-.33-.15-1.018-.866-1.018A.818.818,0,0,0,11.7,2.3a1.1,1.1,0,0,0-.172.966c.051.415.128.97.236,1.6.306,3.209.45,9.32-2.02,14.174q-.386.759-.769,1.455C4.356,21.848.626,24.245.08,26.234a1.964,1.964,0,0,0,.485,1.944,2.815,2.815,0,0,0,2.087.936c2.187,0,4.68-2.436,7.409-7.236a23.438,23.438,0,0,1,3.588-.66c.454-.046,1.19-.142,1.641-.211a22.5,22.5,0,0,1,4.1-.222c3.351,3.483,6.09,5.249,8.144,5.249A2.86,2.86,0,0,0,30.1,24.52,1.873,1.873,0,0,0,30.106,22.631ZM2.651,27.5a1.23,1.23,0,0,1-.921-.438.357.357,0,0,1-.094-.4c.282-1.024,2.648-2.779,6.106-4.054C5.788,25.692,3.918,27.5,2.651,27.5Zm12.392-8.087c-.429.066-1.127.157-1.558.2a23.616,23.616,0,0,0-2.4.367l.1-.2a22.966,22.966,0,0,0,2.239-8.5,23.754,23.754,0,0,0,4.321,7.71c.052.06.106.12.157.182A22.354,22.354,0,0,0,15.043,19.413ZM28.7,23.727a1.261,1.261,0,0,1-1.2.695h0c-1.283,0-3.256-1.215-5.618-3.442,3.656.459,6.333,1.566,6.813,2.433A.265.265,0,0,1,28.7,23.727Z"*/}
              {/*transform="translate(0 -2.031)" fill="#fff"></path></svg></i></span> DOWNLOAD WHITE*/}
              {/*PAPER*/}
              {/*</button>*/}
            </div>
          </div>

          <div className="image">
            <img src={WhatIsMartianGrapic} alt={"What is Martian"} />
          </div>

          <div className="about-bottom flex">
            <div className="goals-wrapper flex">
              {goals.map((goal, index) => {
                return (
                  <div key={index} className={`goal ${goal.name} flex`}>
                    <h3>
                      {goal.name.charAt(0).toUpperCase() + goal.name.slice(1)}
                    </h3>
                    {goal.element}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="stripe" />
        </div>
      </section>
    );
  }
}

About.propTypes = {
  title: PropTypes.string.isRequired,
};

export default About;
