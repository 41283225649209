import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import './App.css';

//imports Child Components
import Layout from '../Layout/Layout.js';
import Whitepaper from '../../components/Whitepaper/Whitepaper';

//imports empty icon library
import { library } from '@fortawesome/fontawesome-svg-core';

//imports icons
import {
  faInstagram,
  faYoutube,
  faGithub,
  faFacebookF,
  faTwitter,
  faTelegramPlane,
  faLinkedinIn,
  faRedditAlien
} from "@fortawesome/free-brands-svg-icons";
import { faBars, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

//merges the icon library
library.add(
  faInstagram,
  faYoutube,
  faGithub,
  faFacebookF,
  faTwitter,
  faTelegramPlane,
  faLinkedinIn,
  faRedditAlien,
  faBars
);


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLinks: [ 'about','functionalities','why?','team', 'roadmap', 'token', 'fAQ'],
      socialIcons: [
        {name: 'linkedin',color: '#2F86C5',image: faLinkedinIn, url: 'https://www.linkedin.com/company/35517375/'},
        // {name: 'telegram',color: '#48B5E4',image: faTelegramPlane, url: 'https://t.me/joinchat/GIGwE02FMCleKoXb3uBOKQ'},
        {name: 'facebook',color: '#3E5E94',image: faFacebookF,url: 'https://www.facebook.com/profile.php?id=100054486789076'},
        // {name: 'twitter',color: '#66AFE9',image: faTwitter, url: 'https://twitter.com/MartianNetwork'},
        {name: 'instagram',color: '#3f729b',image: faInstagram, url: 'https://www.instagram.com/martiannetwork/'},
        // {name: 'youtube',color: '#c4302b',image: faYoutube, url: 'https://www.youtube.com/channel/UCAeddqeiJKclYtaGMnN9CGQ?disable_polymer=true'},
        // {name: 'reddit',color: '#FC471E',image: faRedditAlien, url: 'https://old.reddit.com/user/Martiannetwork/'},
        // {name: 'github',color: '#6e5494',image: faGithub, url: 'https://github.com/MartianNetwork'},
      ]
    }
  }

  render() {
    const { navLinks, socialIcons } = this.state;


    return (
      <div className="App">
        <Switch>
          <Route
            exact path="/"
            render={(props) => (
              <Layout
                navLinks={navLinks}
                socialIcons={socialIcons}
                hamburgerIcon={faBars}
                arrowUp={faChevronUp}
                arrowDown={faChevronDown}
                linkedin={faLinkedinIn}
              />
            )}
          />
          <Route path="/whitepaper" component={Whitepaper} />
        </Switch>
      </div>
    );
  }
}

export default App;
