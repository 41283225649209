import React, { Component } from "react";
import PropTypes from "prop-types";
// import InlineSVG from 'svg-inline-react';
import "./Details.css";

// const redlineIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="10" viewBox="0 0 30 5"><rect id="Rectangle_1010" data-name="Rectangle 1010" width="5" height="30" rx="2.5" transform="translate(0 5) rotate(-90)" fill="#cf2930"/></svg>`;

class Details extends Component {
  render() {
    const { title, linedTitle, titleTop, titleBottom } = this.props;
    let element;

    if (title === "blockchain") {
      element = (
        <p className="description">
          Martian is dedicated to build a revolutionary incentivized peer
          -to-peer entertainment platform which enables users to share digital
          content and take control over their own content.
        </p>
      );
    } else if (title === "about") {
      element = (
        <p className="description">
          Martian is dedicated to building a revolutionary incentivized
          peer-to-peer entertainment eosystem which enables users to share
          digital content and take control over their own content.
          <br />
          <br />
          Martian utilizes the blockchain, capable virtual machines and its
          smart-contracts to eventually host a fully decentralized entertainment
          ecosystem. The types of digital content the ecosystem supports are:
          video, music ,games & digital photo’s.
        </p>
      );
    } else if (title === "infrastructure") {
      element = "";
    } else if (title === "roadmap") {
      element = "";
    } else if (title === "team") {
      element = "";
    } else if (title === "tokens") {
      element = (
        <p className="description">
          The Martian token (MAR), the unique token for the platform will be
          used to incentivize users and also for brand identification.
        </p>
      );
    }

    return (
      <div className="details flex">
        <div className="redline-wrapper flex">
          <span>{linedTitle}</span>
        </div>
        <h2 className="section-title">{titleTop}</h2>
        <h2 className="section-title">{titleBottom}</h2>
        {element}
      </div>
    );
  }
}

Details.propTypes = {
  title: PropTypes.string.isRequired,
  linedTitle: PropTypes.string.isRequired,
  titleTop: PropTypes.string.isRequired,
  titleBottom: PropTypes.string.isRequired,
};

export default Details;
