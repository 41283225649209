import React, { Component } from "react";
import PropTypes from "prop-types";
// import InlineSVG from 'svg-inline-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Team.css";
import Details from "../Details/Details.js";
import LinkedIn from "../../assets/img/linkedin.svg";
import Twitter from "../../assets/img/twitter.svg";
import Instagram from "../../assets/img/instagram-logo.svg";

// const redlineIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="10" viewBox="0 0 30 5"><rect id="Rectangle_1012" data-name="Rectangle 1010" width="5" height="30" rx="2.5" transform="translate(0 5) rotate(-90)" fill="#cf2930"/></svg>`;

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {
        "Executive Team": {
          members: [
            {
              name: "Michael Antwi",
              avatar: require("../../assets/img/ceo.jpg"),
              role: "Founder & CEO",
              linkedinUrl:
                "https://www.linkedin.com/in/michael-antwi-9b672958/",
              twitterUrl: "https://twitter.com/Michael__Antwi",
            },
            {
              name: "Felicia Onbekent",
              avatar: require("../../assets/img/cbo.jpg"),
              role: "CBO",
              linkedinUrl:
                "https://www.linkedin.com/in/felicia-onbekent-528b09b8/",
            },
            // {
            //   name: "Lillian Namusisi",
            //   avatar: require("../../assets/img/developer.jpg"),
            //   role: "Lead Developer",
            //   linkedinUrl:
            //     "https://www.linkedin.com/in/lillian-namusisi-a3931079/",
            // },
            {
              name: "Ruud Finkie",
              avatar: require("../../assets/img/cfo.jpg"),
              role: "CFO",
              linkedinUrl: "https://www.linkedin.com/in/ruud-finkie-15973a93/",
            },
            {
              name: "Mohammed Alyazji",
              avatar: require("../../assets/img/mohammed.jpg"),
              role: "UI/UX Designer",
              linkedinUrl: "https://www.linkedin.com/in/mohammedalyazji/",
            },
          ],
        },
        // 'Tech Team': {
        //   members: [
        //     {name: 'Mohammed Alyazji', avatar: require('../../assets/img/mohammed.jpg'), role: 'UI/UX Designer', linkedinUrl: 'https://www.linkedin.com/in/mohammedalyazji/'},
        //     {name: 'Ishan Gunawardane', avatar: require('../../assets/img/ishan.jpg'), role: 'UI/UX Designer', linkedinUrl: 'https://www.linkedin.com/in/ishangunawardane/'},
        //     {name: 'Razvan Uscatu', avatar: require('../../assets/img/razvan.jpg'), role: 'Frontend', linkedinUrl: 'https://www.linkedin.com/in/razvan-uscatu-012751a1/'},
        //     {name: 'Jude Okagu', avatar: require ('../../assets/img/jude.jpg'), role: 'Frontend', linkedinUrl: 'https://www.linkedin.com/in/jude-okagu-6142b913b/'},
        //     {name: 'Mustafa Refaey', avatar: require('../../assets/img/mustafa.jpg'), role: 'Fullstack Web Developer', linkedinUrl: 'https://www.linkedin.com/in/mustafarefaey/'},
        //     {name: 'Franklin Ekoh', avatar: require('../../assets/img/franklin.jpg'), role: 'Backend', linkedinUrl: 'https://www.linkedin.com/in/franklin-ekoh-a11805122/'},
        //     {name: 'Suchita Singh', avatar: require('../../assets/img/suchita.jpg'), role: 'Fullstack', linkedinUrl: 'https://www.linkedin.com/in/suchitaa-singh/'},
        //     {name: 'Nse-Abasi Etim', avatar: require('../../assets/img/nse.jpg'), role: 'Backend', linkedinUrl: 'https://www.linkedin.com/in/nseetim/'},
        //     {name: 'Seif Abaza', avatar: require('../../assets/img/seif.jpg'), role: 'Blockchain', linkedinUrl: 'https://www.linkedin.com/in/seif-abaza/'},
        //     {name: 'Poojah Trikah', avatar: require('../../assets/img/pojah.svg'), role: 'Fullstack', linkedinUrl: 'https://www.linkedin.com/in/pooja-kaul-9a7192110/'},
        //     {name: 'Daniel Weaver', avatar: require('../../assets/img/daniel.svg'), role: 'Blockchain', linkedinUrl: 'https://www.linkedin.com/in/danweaver1/'},
        //     {name: 'Mahesh Patel', avatar: require('../../assets/img/mahesh.svg'), role: 'Fullstack', linkedinUrl: 'https://www.linkedin.com/in/maheshphp/'},
        //     {name: 'Patric Slagveer', avatar: require('../../assets/img/patric.svg'), role: 'Frontend', linkedinUrl: 'https://www.linkedin.com/in/patricslagveer/'}
        //   ]
        // },
        // 'Meet our advisors': {
        //   members: [
        //     {name: 'Maincode', avatar: require('../../assets/img/maincode.png'), role: 'UI/UX & Web Development agency', linkedinUrl: 'https://www.linkedin.com/company/main-code/about/'},
        //     {name: 'Albert Esu', avatar: require('../../assets/img/albert.jpg'), role: 'Filmmaker in Nollywood', linkedinUrl: 'https://www.linkedin.com/in/albert-esu-725675149/'},
        //     {name: 'Asurf Oluseyi', avatar: require('../../assets/img/asurf.svg'), role: 'FILM DIRECTOR CEO & FOUNDER ASURF FILMS LIMITED', linkedinUrl: 'https://www.linkedin.com/in/asurf/', instagramUrl: 'https://www.instagram.com/iamasurf/?hl=nl'},
        //     {name: 'Emmanuel Odole', avatar: require('../../assets/img/emmanuel.svg'), role: 'CONTENT AGGREGATOR/BROKER TV PRODUCER, FOUNDER & CEO BLACKDRUM 360', linkedinUrl: 'https://www.linkedin.com/in/emmanuelodole/', instagramUrl: 'https://www.instagram.com/eodole/'},
        //     {name: 'James Abinibi', avatar: require('../../assets/img/james.svg'), role: 'FILM DIRECTOR CEO & FOUNDER ABINIBI MULTIMEDIA', linkedinUrl: 'https://www.linkedin.com/in/abinibi/', instagramUrl: 'https://www.instagram.com/abinibi/?hl=nl'},
        //     {name: 'Yomi Black', avatar: require('../../assets/img/yomi.svg'), role: 'FILM DIRECTOR CEO & FOUNDER BLACK STUDIOS', linkedinUrl: 'https://www.linkedin.com/in/yomi-black-64174833/', instagramUrl: 'https://www.instagram.com/yomiblack/?hl=nl'}
        //   ]
        // }
      },
    };
    console.log(Object.keys(this.state.team));
  }

  renderLink(url, type) {
    let result;
    if (url) {
      switch (type) {
        case "linkedin":
          result = LinkedIn;
          break;
        case "twitter":
          result = Twitter;
          break;
        default:
          result = Instagram;
      }
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img className="{type}" alt="Martian" src={result} style={{marginLeft: "10px"}} />
        </a>
      );
    }
  }

  render() {
    const { team } = this.state;
    const { linkedin } = this.props;

    return (
      <section>
        <div
          id="team"
          className="team has-desktop-padding has-mobile-padding flex"
        >
          <div className="stripe" />
          <Details
            title={this.props.title}
            linedTitle=""
            titleTop="Meet our team"
            titleBottom=""
          />
          <div className="stripe" />
          <div className="team-container flex">
            {Object.keys(team).map((members, index) => {
              return (
                <div key={index}>
                  <div className="team-row flex">
                    <div className="team-name flex">
                      {/*<InlineSVG src={redlineIcon} className="icon team-icon"/>*/}
                      {/* <span className="team-name-title">{members}</span> */}
                    </div>

                    {team[members].members.map((member, index) => {
                      return (
                        <div key={index} className="team-member flex">
                          <div className="member-image flex">
                            <img src={member.avatar} alt="A Human Being" />
                            <div className="overlay">
                              <a
                                href={member.linkedinUrl}
                                className="overlay-icon"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={`linkedin-icon ${index}`}
                              >
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={linkedin}
                                />
                              </a>
                            </div>
                          </div>

                          <div className="member-body">
                            <div className="detailsBox">
                              <h3>{member.name}</h3>
                              {/*<a href={member.linkedinUrl} target="_blank" rel="noopener noreferrer"*/}
                              {/*aria-label={`linkedin-icon ${index}`}>*/}
                              {/*<img src={LinkedIn}/>*/}
                              {/*</a>*/}
                              <div className="member-details">
                                {this.renderLink(
                                  member.linkedinUrl,
                                  "linkedin"
                                )}
                                {this.renderLink(member.twitterUrl, "twitter")}
                                {this.renderLink(
                                  member.instagramUrl,
                                  "instagram"
                                )}
                              </div>
                            </div>
                            <h5 className="uppercase">{member.role}</h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = {
  linkedin: PropTypes.object.isRequired,
};

export default Team;
